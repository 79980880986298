import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import moment from 'moment';
import { showModal } from 'legacy/actions/modalActions';
import { getOrg } from 'legacy/actions/orgActions';
import UnverifiedEmailBanner from 'legacy/auth/components/unverified-email/UnverifiedEmailBanner';
import { FLAGS } from 'legacy/common/constants/launchdarkly';
import { useGetHostingRequirementsLink } from 'legacy/user/hooks';

export const BannerContainer = ({ user, showModal, orgs, events, getOrg }) => {
  const flags = useFlags();
  const { mutateAsync: getHostingRequirementsLink } =
    useGetHostingRequirementsLink();
  const router = useRouter();
  const { query } = router;
  const { slug = [] } = query;
  const [eventId] = slug;
  const event = events[eventId];
  // const { organizations } = user;
  const eventOrgId = event?.organization?._id;
  useEffect(() => {
    if (eventOrgId && !orgs[eventOrgId]) {
      getOrg(eventOrgId);
    }
  }, [event]);
  const org = orgs[eventOrgId];
  const { role } = org || {};

  const [isVisible, setVisible] = useState(false);

  const showVerificationForm = async ({
    onSuccess,
    onRequestClose,
    onBack
  }) => {
    const { required, collectable, recollect } =
      user?.connect?.verification?.actions;

    const fields = required || collectable || recollect || [];

    const verificationFields = [
      'firstName',
      'lastName',
      'dob',
      'address',
      'email',
      'phone',
      'tosAccepted',
      'ssnLast4Provided'
    ];

    if (verificationFields.some((field) => fields.includes(field))) {
      showModal('CREATE_CONNECT', {
        orgId: role === 'admin' && eventOrgId,
        org,
        onSuccess,
        onRequestClose,
        onBack
      });

      return;
    }

    if (fields.includes('bankAdded')) {
      showModal('ADD_BANK', {
        onSuccess,
        onRequestClose,
        onBack
      });
      return;
    }

    const hostingRequirementsLinkResponse = await getHostingRequirementsLink();
    router.push(hostingRequirementsLinkResponse.link);
  };

  // no-op
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onDone = () => {};

  useEffect(() => {
    if (router.query.shouldVerify === '1') {
      showVerificationForm({
        onSuccess: onDone,
        onRequestClose: onDone,
        onBack: onDone
      });
    }
  }, [router.query.shouldVerify]);

  useEffect(() => {
    const verificationActions = user?.connect?.verification?.actions ?? {};
    const { recollect, required, collectable } = verificationActions;

    if (recollect?.length || required?.length || collectable?.length) {
      setVisible(true);
      return;
    }

    setVisible(false);
  }, [user?.connect?.verification?.actions.message]);

  // Do not show legal verification banners when email is not verified.
  if (
    flags[FLAGS.ENABLE_UNVERIFIED_EMAIL_VERIFICATION_ALERT] &&
    !!user?.email &&
    user?.isEmailVerified === false
  ) {
    return <UnverifiedEmailBanner />;
  }

  if (!isVisible) {
    return null;
  }

  const verificationActions = user?.connect?.verification?.actions ?? {};
  const { recollect, required, collectable } = verificationActions;

  let bannerType = 'successBanner';

  if (required?.length) {
    bannerType = 'connectBanner';
  }

  if (collectable?.length) {
    bannerType = 'bankBanner';
  }

  if (recollect?.length) {
    bannerType = 'connectBanner';
  }

  return (
    <div
      id={bannerType}
      className="banner bounce-banner"
      data-cy="legal-verification-banner"
    >
      <div
        onClick={() => {
          showVerificationForm({
            onSuccess: () => {
              setVisible(false);
            }
          });
        }}
      >
        <i className="fas fa-info-circle" />
        <div className="banner-text">
          <p>
            {verificationActions?.message}
            <br />
            {verificationActions.payoutDisabledDeadline && (
              <>
                Deadline:{' '}
                <b>
                  {moment(verificationActions.payoutDisabledDeadline).format(
                    'MMMM Do, YYYY'
                  )}
                </b>
              </>
            )}
          </p>
        </div>
      </div>
      <i
        className="fas fa-times"
        onClick={() => {
          setVisible(false);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  orgs: state.orgs.orgs,
  events: state.events.events
});

const actions = { showModal, getOrg };

export default connect(mapStateToProps, actions)(BannerContainer);
