import styles from './PrivateHeader.module.scss';

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { Modal } from 'antd';
import { FaTimes } from 'react-icons/fa';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { FLAGS } from 'legacy/common/constants/launchdarkly';

import * as datetime from 'legacy/common/utils/datetime';

import {
  viewNotifications,
  deleteNotification
} from 'legacy/actions/notificationActions';
import { logout } from 'legacy/actions/userActions';

import { useWindowSize, useDesktopOnlyHover } from '../../../util/hooks';

import NavLink from '../../util/NavLink/NavLink';
import BannerContainer from './BannerContainer';
import Search from 'legacy/search/screens/Search.screen';
import Dropdown from '../../util/Dropdown/Dropdown';
import { Emblem } from '../../pages/Profile/User/components';
import DefaultUserPhoto from '../../util/DefaultUserPhoto/DefaultUserPhoto';
import Link from 'next/link';

function PrivateHeader({
  logout,
  viewNotifications,
  deleteNotification,
  user,
  notifications,
  device
}) {
  const flags = useFlags();
  const router = useRouter();
  const { pathname, query } = router;

  const [width, height] = useWindowSize();
  const [notifHover, setNotifHover, clearNotifHover] = useDesktopOnlyHover();
  const ticking = useRef(false);
  const setTicking = useCallback((val) => {
    ticking.current = val;
  }, []);
  const [menuScrollLeft, setMenuScrollLeft] = useState(0);
  const underline = useRef(null);
  const menu = useRef(null);
  const slideLinks = ['dashboard', 'host'];
  const unreadNotifications = notifications.filter((n) => !n.viewed);

  function hide() {
    underline.current.style.visibility = 'hidden';
  }

  function slide() {
    if (!underline.current) {
      return;
    }

    const link = pathname.replace('/', '') || 'home';
    if (!slideLinks.includes(link)) {
      hide();
    } else {
      const item = document.querySelector(`#${link}`);
      const rect = item.getBoundingClientRect();

      underline.current.style.visibility = 'visible';
      underline.current.style.left = `${rect.left + menuScrollLeft}px`;
      underline.current.style.width = `${rect.width}px`;
    }
  }

  useEffect(slide, [
    width,
    height,
    menuScrollLeft,
    user,
    pathname,
    device,
    slideLinks
  ]);

  const adjustMenuScrollPos = useCallback(
    (scrollLeft) => {
      if (!ticking.current) {
        setTicking(true);
        requestAnimationFrame(() => {
          setMenuScrollLeft(scrollLeft);
          setTicking(false);
        });
      }
    },
    [ticking, setMenuScrollLeft, setTicking]
  );

  const handleLinkScroll = useCallback(
    (e) => {
      adjustMenuScrollPos(e.target.scrollLeft);
    },
    [adjustMenuScrollPos]
  );

  function handleViewNotifications() {
    viewNotifications(unreadNotifications.map((n) => n._id));
  }

  function viewNotification(notification) {
    // use new redirect property from backend
    if (notification.redirectPath) {
      router.push(notification.redirectPath);
    } else {
      // keep in case some legacy notifications don't have redirect prop
      switch (notification.type) {
        case 'friendRequestAccepted':
        case 'friendRequestReceived':
          router.push(`/profile/${notification.actor}`);
          break;
        case 'p2pRequestReceived':
          router.push('/payments?view=receivedRequests');
          break;
        case 'p2pTransferReceived':
          router.push('/payments?view=incoming');
          break;
        case 'p2pReceiveFailure':
          router.push('/host');
          break;
        case 'addedAsOperator':
        case 'eventInviteReceived':
        case 'eventBounce':
        case 'hostBounce':
        case 'hostMax':
        case 'paymentFailure':
          router.push(`/events/${notification.event}`);
          break;
        default:
          break;
      }
    }
  }

  let notificationLinks = notifications.map((notification) => {
    const notificationClass = classnames('item', {
      unread: !notification.viewed
    });
    return (
      <div
        key={notification._id}
        className={notificationClass}
        onClick={() => viewNotification(notification)}
      >
        <i
          className="fas fa-times"
          title="delete this notification"
          onClick={(e) => {
            e.stopPropagation();
            deleteNotification(notification._id);
          }}
          data-cy="delete-notification-button"
        />
        <div>{notification.message}</div>
      </div>
    );
  });

  const getColorByType = (type) => {
    switch (type) {
      case 'friendRequestAccepted':
      case 'friendRequestReceived':
        return 'text-purple-600';

      case 'p2pRequestReceived':
      case 'p2pTransferReceived':
        return 'text-green-600';

      case 'hostMax':
      case 'eventAddressChanged':
      case 'p2pVerificationRequest':
      case 'infoRequiredForPayout':
      case 'unclaimedTicketsRemaining':
        return 'text-orange-600';

      case 'custom':
      case 'addedAsCohost':
      case 'addedAsOperator':
      case 'ticketClaimed':
      case 'nearbyEventsFound':
      case 'eventInviteReceived':
      case 'eventFeedItemCreated':
      case 'ticketPurchasedByFriend':
        return 'text-primary-600';

      case 'eventCancelled':
      case 'eventPayoutFailure':
      case 'p2pPayoutFailure':
      case 'paymentFailure':
        return 'text-red-500';

      default:
        return 'text-gray-700';
    }
  };

  const renderNotifications = () => {
    if (notifications?.length === 0) {
      return (
        <div className="px-4 py-10 text-center">
          <div>You have no notifications.</div>
        </div>
      );
    }

    return notifications?.map((notification) => (
      <div
        key={notification._id}
        className={classnames(
          'flex p-4 items-center justify-between border-b-1 hover:bg-primary-200',
          {
            'bg-blue-50': !notification.viewed
          }
        )}
        onClick={() => viewNotification(notification)}
      >
        <div className="flex">
          <div className="flex-shrink-0 mr-4">
            <img
              src={notification.image}
              className="w-16 h-16 object-cover rounded-full"
            />
          </div>
          <div>
            <div
              className={classnames(
                'flex items-center text-base',
                getColorByType(notification.type)
              )}
            >
              {!notification.viewed && (
                <div className="w-2 h-2 rounded-full bg-primary-500 mr-2 animate-pulse" />
              )}
              {notification.title}
            </div>
            <div className="mt-1 text-sm">{notification.message}</div>
            <div className="mt-1 text-sm text-gray-400">
              {datetime.fromNow(notification.createdAt)}
            </div>
          </div>
        </div>

        <div className="ml-4">
          <FaTimes
            className="text-lg text-gray-400"
            data-cy="delete-notification-button"
            onClick={(e) => {
              e.stopPropagation();
              deleteNotification(notification._id);
            }}
          />
        </div>
      </div>
    ));
  };

  if (notificationLinks.length === 0) {
    notificationLinks = (
      <div className="item no-notifs">You have no notifications</div>
    );
  }

  const links = slideLinks.map((link) => (
    <NavLink key={link} id={link} activeHref={`/${link}`}>
      <span className="text-base font-normal capitalize">
        {/* Yeah... I know... */}
        {link === 'dashboard' ? 'Home' : link}
      </span>
    </NavLink>
  ));

  const names = user.name ? user.name.split(' ') : [];
  if (names.length > 1) {
    names.pop();
  }
  const firstname = names.join(' ');

  const showOrgsModal = () => {
    const orgsModal = Modal.info({
      title: 'My organizations',
      icon: null,
      maskClosable: true,
      closable: true,
      content: (
        <div className={styles.organizations}>
          {user.organizations.map(({ name, _id, emblem }) => (
            <div
              data-cy={`org-${name}`}
              key={_id}
              className={styles.organization}
              onClick={() => {
                router.push(`/profile/?org=${_id}`);
                orgsModal.destroy();
              }}
            >
              <span>{name}</span>
              <Emblem emblem={emblem} />
            </div>
          ))}
        </div>
      ),
      okButtonProps: {
        style: { display: 'none' }
      },
      centered: true
    });
  };

  const isCreateEditOrViewPage =
    pathname.includes('/events/create') ||
    router.pathname.includes('/events/[slug]/dashboard') ||
    router.pathname.includes('/events/[slug]/approval') ||
    query.view === 'edit' ||
    router.route === '/events/[slug]';

  const isCreateEditOrViewOrgPage = pathname.includes('/organizations');
  const isCreateEditOrViewAssociationPage = pathname.includes('/associations');

  return (
    <div
      id="bounce-header"
      className={classnames(
        [styles.PrivateHeader],
        device,
        {
          // Hide header only on mobile view for create page
          'hidden md:block':
            isCreateEditOrViewPage ||
            isCreateEditOrViewOrgPage ||
            isCreateEditOrViewAssociationPage
        },
        'bg-primary-400'
      )}
    >
      <div className={classnames('bn-container', device)}>
        {/* top bar */}
        <div className={classnames('bn-row', styles.header)}>
          <Link href="/dashboard" legacyBehavior>
            <img className="w-36 mr-10" src="/img/logo.svg" alt="Bounce" />
          </Link>
          <Search />
        </div>

        {/* bottom bar */}
        <div className="bn-row bottom">
          <div className="links" onScroll={handleLinkScroll} ref={menu}>
            {links}

            <div className="hidden sm:block">
              <NavLink key="tickets" id="tickets" activeHref="/tickets">
                <span className="font-normal text-base">Tickets</span>
              </NavLink>
            </div>
          </div>
          {user && (
            <>
              <div
                className={classnames('notifications', {
                  hover: notifHover
                })}
                onMouseEnter={setNotifHover}
                onMouseLeave={clearNotifHover}
              >
                <Dropdown
                  classes={classnames({
                    [styles.notificationDropdown]:
                      flags[FLAGS.ENABLE_NOTIFICATION_REDESIGN]
                  })}
                  btn_content={
                    <div
                      className="icon select-none"
                      onClick={handleViewNotifications}
                      data-cy="notification-bell-icon"
                    >
                      <i className="far fa-bell" />
                      {unreadNotifications.length > 0 && (
                        <div className="unread">
                          {unreadNotifications.length}
                        </div>
                      )}
                    </div>
                  }
                >
                  <div>
                    {flags[FLAGS.ENABLE_NOTIFICATION_REDESIGN]
                      ? renderNotifications()
                      : notificationLinks}
                  </div>
                </Dropdown>
              </div>
              <Dropdown
                classes={styles.userMenu}
                btn_content={
                  <>
                    <div className="pic" data-cy="profile-picture-icon">
                      {user.photo ? (
                        <img src={user.photo.url} alt="" />
                      ) : (
                        <DefaultUserPhoto />
                      )}
                    </div>
                    <span>{firstname}</span>
                  </>
                }
              >
                <div
                  className={styles.profileLink}
                  onClick={() => router.push('/profile')}
                >
                  <div className="pic">
                    {user.photo ? (
                      <img src={user.photo.url} alt="" />
                    ) : (
                      <DefaultUserPhoto />
                    )}
                  </div>
                  <div className={styles.userName}>
                    <div>{user.name}</div>
                    <p>See my profile</p>
                  </div>
                </div>

                <div
                  className={styles.menuLink}
                  onClick={() => router.push('/profile?view=settings')}
                >
                  <i className="fa fa-user-circle" />
                  <span>Account Settings</span>
                  <i className="fa fa-chevron-right" />
                </div>
                {user?.organizations?.length > 0 && (
                  <div
                    className={styles.menuLink}
                    onClick={() => showOrgsModal()}
                  >
                    <i className="fa fa-sitemap" />
                    <span>My Organizations</span>
                    <i className="fa fa-chevron-right" />
                  </div>
                )}
                <div
                  className={styles.menuLink}
                  onClick={() => router.push('/payments')}
                >
                  <i className="fa fa-wallet" />
                  <span>Payment</span>
                  <i className="fa fa-chevron-right" />
                </div>
                <div className={styles.menuLink} onClick={logout}>
                  <i className="fa fa-sign-out-alt" />
                  <span>Logout</span>
                  <i className="fa fa-chevron-right" />
                </div>
              </Dropdown>
            </>
          )}
        </div>
      </div>
      <BannerContainer router={router} pathname={pathname} />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    events: state.events.events,
    notifications: state.notifications.notifications,
    device: state.app.device
  };
}

const actions = {
  logout,
  viewNotifications,
  deleteNotification
};

export default connect(mapStateToProps, actions)(PrivateHeader);
