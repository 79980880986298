import { User, UserAllPhoto } from 'legacy/common/types/models';
import { GenericSuccessResponse } from 'legacy/common/types/response';
import bounceApiClient from 'legacy/common/utils/bounceApiClient';

import { EventLean, RequiredEventRatingsDto } from 'legacy/events/types';
import {
  EditUser,
  EditSuggestions,
  UserMe,
  OnboardingRequirements,
  MigrationRequirementsDto,
  CanTextAllDto,
  HostingRequirementsLinkResponse,
  HostingRequirementsLinkParams
} from 'legacy/user/types';

import { decodeJWT } from 'legacy/auth/helpers';

import storage from 'legacy/common/utils/storage';
import { STORAGE_KEYS } from 'legacy/common/constants/storage';
import { VerificationSessionDto } from 'legacy/auth/types';
import { AxiosResponse } from 'axios';

export async function getMe(): Promise<UserMe> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: '/api/users/me'
  });

  return data;
}

export async function getUser(id: string): Promise<User> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: `/api/users/${id}`
  });

  return data;
}

export async function getEditSuggestions(): Promise<EditSuggestions> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: '/api/users/me/editSuggestions'
  });

  return data;
}

export async function editMe(body: EditUser): Promise<UserMe> {
  const { data } = await bounceApiClient({
    method: 'put',
    url: '/api/users/me/',
    data: body
  });

  return data;
}

export async function postOnboardingRequirements(
  body: OnboardingRequirements
): Promise<UserMe> {
  const { data } = await bounceApiClient({
    method: 'put',
    url: '/api/users/me/onboardingRequirements',
    data: body
  });

  return data;
}

export async function getHostedEvents(): Promise<EventLean[]> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: '/api/users/me/hostedEvents'
  });

  return data;
}

export async function getRequiredEventRatings(): Promise<RequiredEventRatingsDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: '/api/users/me/requiredEventRatings'
  });

  return data;
}

export async function sendFriendRequest(
  userId: string
): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient.post<GenericSuccessResponse>(
    `/api/friends`,
    {
      receiver: userId
    }
  );

  return data;
}

export async function cancelFriendRequest(
  userId: string
): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient.post<GenericSuccessResponse>(
    `/api/friends/requests/cancel`,
    {
      receiver: userId
    }
  );

  return data;
}

export async function acceptFriendRequest(
  userId: string
): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient.post(`/api/friends/requests/accept`, {
    sender: userId
  });

  return data;
}

export async function declineFriendRequest(
  userId: string
): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient.post('/api/friends/requests/decline', {
    sender: userId
  });

  return data;
}

export async function removeFriend(
  userId: string
): Promise<GenericSuccessResponse> {
  const { data } = await bounceApiClient.delete(`/api/friends/${userId}`);

  return data;
}

export async function uploadProfilePicture(
  blob: Blob
): Promise<{ photo: string }> {
  const formData = new FormData();
  formData.append('image', blob);

  const { data } = await bounceApiClient.post('/api/users/me/photo', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });

  return data;
}

export async function uploadGalleryPhoto(
  blob: Blob
): Promise<{ allPhotos: UserAllPhoto[]; photo: UserAllPhoto }> {
  const formData = new FormData();
  formData.append('image', blob);

  const { data } = await bounceApiClient.post(
    '/api/users/me/photos',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

  return data;
}

export function getLoggedInUserId() {
  const token = storage.getItem(STORAGE_KEYS.AUTH_TOKEN);
  if (!token) {
    return '';
  }

  const decodedToken = decodeJWT(token);
  return decodedToken?._id || '';
}

export async function createEditPhoneVerificationSession({
  phone,
  countryCode
}: {
  phone: string;
  countryCode: string;
}): Promise<VerificationSessionDto> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: '/api/users/me/phone/verificationSessions',
    data: {
      phone,
      countryCode: countryCode ? parseInt(countryCode) : null
    }
  });

  return data;
}

export async function confirmEditPhoneVerificationSession({
  verificationSessionId,
  smsCode
}: {
  verificationSessionId: string;
  smsCode: string;
}): Promise<UserMe> {
  const { data } = await bounceApiClient({
    method: 'post',
    url: `/api/users/me/phone/verificationSessions/${verificationSessionId}/confirm`,
    data: {
      smsCode
    }
  });

  return data;
}

export async function updateEmail({
  email,
  password
}: {
  email: string;
  password: string;
}): Promise<UserMe> {
  const { data } = await bounceApiClient({
    method: 'put',
    url: '/api/users/me/email',
    data: {
      email,
      password
    }
  });

  return data;
}

export async function getAuthMigrationRequirements(): Promise<MigrationRequirementsDto> {
  const { data } = await bounceApiClient({
    method: 'get',
    url: '/api/users/me/authMigrationRequirements'
  });

  return data;
}

export async function getCanTextAll(): Promise<CanTextAllDto> {
  const { data } = await bounceApiClient.get(`/api/users/me/canTextAll`);

  return data;
}

export async function getHostingRequirementsLink(
  eventId: string
): Promise<HostingRequirementsLinkResponse> {
  const { data } = await bounceApiClient.post<
    HostingRequirementsLinkResponse,
    AxiosResponse<HostingRequirementsLinkResponse>,
    HostingRequirementsLinkParams
  >(`/api/users/me/requirementsLink`, { eventId });

  return data;
}
