export const LAUNCHDARKLY_USER_KEYS = {
  ANONYMOUS: 'anonymous_user',
  CYPRESS_TESTS: 'cypress_test_user'
};

export const FLAGS = {
  ENABLE_FRIEND_SUGGESTIONS_FOR_SUCCESS_VIEW:
    'enable-friend-suggestions-for-success-view',
  ENABLE_REDIRECT_ON_CHECKOUT_SESSION_COMPLETED_ERROR:
    'enable-redirect-on-checkout-session-completed-error',
  ENABLE_NEW_SIGN_UP_FLOW: 'enable-new-sign-up-flow',
  ENABLE_NEW_LOGIN_SCREEN: 'enable-new-login-screen',
  ENABLE_USER_REQUIREMENTS_ONBOARDING_API:
    'enable-user-requirements-onboarding-api',
  ENABLE_EMAIL_EDIT: 'enable-email-edit',
  ENABLE_AUTH_MIGRATION_FLOW: 'enable-auth-migration-flow',
  ENABLE_UNVERIFIED_EMAIL_VERIFICATION_ALERT:
    'enable-unverified-email-verification-alert',
  ENABLE_NOTIFICATION_REDESIGN: 'enable-notification-redesign',
  ENABLE_TAXES_IN_CHECKOUT: 'enable-taxes-in-checkout',
  ENABLE_TAXES_IN_EVENT_FORM: 'enable-taxes-in-event-form',
  ENABLE_GROUPS_AS_HOST: 'enable-groups-as-host',
  ENABLE_GROUPS: 'enable-groups',
  ENABLE_FEATURE_REMINDERS: 'enable-feature-reminders',
  ENABLE_CONFETTI_VIEW: 'enable-confetti-view',
  ENABLE_BOUNCE_BRANDED_QR_CODES: 'enable-bounce-branded-qr-codes',
  ENABLE_EVENT_FEES_BREAKDOWN: 'enable-event-fees-breakdown',
  ENABLE_TEXT_ALL_GUESTS: 'enable-text-all-guests',
  ENABLE_BUY_MORE_TICKETS: 'enable-buy-more-tickets',
  ENABLE_MANAGE_TICKET_SCANNERS: 'enable-manage-ticket-scanners',
  ENABLE_TIMEBOUND_TIERS: 'enable-timebound-tiers',
  ENABLE_EVENT_WAIVER: 'enable-event-waiver',
  ENABLE_EVENT_ORGANIZER_FEE: 'enable-event-organizer-fee',
  ENABLE_SAVE_AND_PUBLISH_EVENTS: 'enable-save-and-publish-events',
  ENABLE_EVENT_BULK_PHOTO_UPLOAD: 'enable-event-bulk-photo-upload'
};
